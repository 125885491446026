import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Registration from "./components/Registration";
import MFEContainer from "./containers/MFEContainer";
import KeyParties from "./views/KeyParties";
import PhoneVerification from "./views/PhoneVerification";

const router = createBrowserRouter([
  {
    path: "/registration",
    element: <Registration />,
  },
  {
    path: "/key-parties",
    element: <KeyParties />,
  },
  {
    path: "/phone-verification",
    element: <PhoneVerification />,
  },
]);

function App() {
  return (
    <MFEContainer language="EN">
      <RouterProvider router={router} />
    </MFEContainer>
  );
}

export default App;
